import React, { useCallback, useRef, useState } from 'react';

import {
  Box,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { useNavigate } from 'react-router-dom';

import { fetchCustomers } from '@/api/customers';
import { SearchIcon } from '@/icons';
import {
  Customer,
  CustomerSearchTypes,
  CustomerSortFields,
} from '@/types/customers';
import { getCustomerName } from '@/utils/customer';

import { PureDropdown } from '../form';

const TYPE_DROPDOWN_OPTIONS = [
  {
    id: 0,
    value: 'standard',
    text: 'Standard',
  },
  {
    id: 1,
    value: 'pin',
    text: 'Personnummer',
  },
  {
    id: 2,
    value: 'email',
    text: 'E-Post',
  },
  {
    id: 3,
    value: 'phone',
    text: 'Telefon',
  },
  {
    id: 4,
    value: 'code',
    text: 'Seriekod',
  },
];

type Props = {
  onSelectCustomer: (customer: Customer) => void;
};

export const CustomerSearchInput = ({ onSelectCustomer }: Props) => {
  const navigate = useNavigate();

  const userSearchInput = useRef<HTMLInputElement>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filter, setFilter] = useState<{
    q: string;
    type: CustomerSearchTypes;
    sortField: CustomerSortFields;
    sortOrder: 'asc' | 'desc';
  }>({
    q: '',
    type: 'standard',
    sortField: 'id',
    sortOrder: 'asc',
  });

  const debouncedUpdateFilter = useCallback(
    debounce((query) => {
      setFilter((prev) => ({ ...prev, q: query }));
    }, 500),
    [],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setInputValue(newValue);

    debouncedUpdateFilter(newValue.trim());
  };

  const {
    data: customers,
    isFetching,
    isSuccess,
  } = useQuery({
    queryKey: ['customerSearch', filter],
    queryFn: async () => {
      const data = await fetchCustomers({
        ...filter,
        q: filter.q.trimStart().trimEnd(),
      });

      return data['hydra:member'].map((item) =>
        pick(item, [
          '@id',
          'id',
          'fullName',
          'company',
          'companyName',
          'category',
          'pin',
        ]),
      );
    },
    initialData: [],
    enabled: filter.q.length >= 1,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setFilter((prev) => ({ ...prev, q: inputValue.trim() }));
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (filter.q.length > 0) {
          // For now, we want to redirect to user to a pasted customer id, no matter if it's in the search results
          // This is because actual search results might be quite long, and we are paginating it by 30
          const customerId = parseInt(filter.q, 10);
          navigate(`/customer/${customerId}`);

          if (userSearchInput.current) {
            userSearchInput.current.blur();
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginBottom: '0.5rem',
          position: 'relative',
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Sök kund"
          size="small"
          fullWidth
          inputRef={userSearchInput}
          value={inputValue}
          style={{ paddingRight: '8px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onFocus={() => setInputFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setInputFocused(false);
              setFilter({ ...filter, q: '' });
              setInputValue('');
            }, 200);
          }}
          onInput={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <input type="submit" hidden />

        <PureDropdown
          sx={{
            width: '50%',
          }}
          size="small"
          labelId="type-select-label"
          label="Söktyp"
          value={filter.type}
          onChange={(event) =>
            setFilter({
              ...filter,
              type: (event.target as HTMLInputElement)
                .value as CustomerSearchTypes,
            })
          }
          options={TYPE_DROPDOWN_OPTIONS}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '55px',
            display: inputFocused ? 'block' : 'none',
          }}
        >
          <Paper>
            <List>
              {isFetching ? (
                <ListItem>
                  <ListItemText primary={<LinearProgress />} />
                </ListItem>
              ) : filter.q.length === 0 ? (
                <ListItem>
                  <ListItemText
                    primary="Skriv något för att söka."
                    secondary="Välj söktyp till höger."
                  />
                </ListItem>
              ) : isSuccess && customers.length === 0 ? (
                <ListItem>
                  <ListItemText>Inget resultat</ListItemText>
                </ListItem>
              ) : (
                customers.map((customer) => (
                  <ListItem
                    key={customer['@id']}
                    onClick={() => {
                      // @ts-ignore
                      onSelectCustomer(customer);
                    }}
                  >
                    <ListItemText>
                      {`${customer.id} - ${getCustomerName(customer)}`}
                    </ListItemText>
                  </ListItem>
                ))
              )}
            </List>
          </Paper>
        </Box>
      </Box>
    </form>
  );
};
