import { useQuery } from '@tanstack/react-query';

import { fetchCustomers } from '@/api/customers';
import { queryKeys } from '@/constants/queryKeys';

import { Filter } from '../components';

type Props = {
  page: number;
  perPage: number;
  filters: Filter;
  debtCollection: boolean;
};

export const useFetchCustomers = ({
  page,
  perPage,
  filters,
  debtCollection,
}: Props) => {
  return useQuery({
    queryKey: [
      queryKeys.getAllCustomers,
      page,
      perPage,
      filters,
      debtCollection,
    ],

    queryFn: async () => {
      const { search, type, sortField, sortOrder } = filters;
      const queryParams: any = {
        type,
        sortField,
        sortOrder,
        page: page + 1,
        perPage,
        debtCollection,
      };

      if (search) {
        queryParams.q = search;
      }

      const data = await fetchCustomers(queryParams);

      return { rows: data['hydra:member'], total: data['hydra:totalItems'] };
    },

    initialData: { rows: [], total: 0 },
  });
};
