import React, { useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { Pagination } from '@/components/Pagination';
import { SimpleTable, SimpleTableColumn } from '@/components/SimpleTable';
import { ServiceIcon } from '@/components/icons';
import { getKebabCaseServiceName, getObjectKeys } from '@/helpers/tsHelpers';
import { usePrivateSettings } from '@/hooks';
import {
  CustomerDTO,
  CustomerSearchTypes,
  CustomerSortFields,
  CustomerSortOrders,
} from '@/types/customers';
import { getCustomerName, getCustomerNameSchema } from '@/utils/customer';

import { useFetchCustomers } from '../hooks';
import { CustomersToolbar } from './CustomersToolbar';

export type Filter = {
  search?: string;
  type: CustomerSearchTypes;
  sortField: CustomerSortFields;
  sortOrder: CustomerSortOrders;
};

export const CustomersTable = ({
  debtCollection,
}: {
  debtCollection: boolean;
}) => {
  const { servicesNames } = usePrivateSettings();
  const navigate = useNavigate();

  const { services } = usePrivateSettings();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);

  const [filters, setFilters] = useState<Filter>({
    type: 'standard',
    sortField: 'id',
    sortOrder: 'asc',
  });

  const {
    data: users,
    isFetching,
    isError,
  } = useFetchCustomers({ page, perPage, filters, debtCollection });

  const handlePageChange = (_: any, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPerPage(rowsPerPage);
  };

  const columns: SimpleTableColumn[] = [
    { label: 'Kundnummer', renderer: (row: CustomerDTO) => row.id },
    {
      label: 'Namn',
      renderer: (row: CustomerDTO) => {
        const result = getCustomerNameSchema.passthrough().safeParse(row);
        if (result.success) {
          return getCustomerName(result.data);
        } else {
          Sentry.captureMessage(result.error.message);
          return '';
        }
      },
    },
    {
      label: 'Tjänster',
      renderer: (row: CustomerDTO) => (
        <Box display="flex" justifyContent="space-evenly">
          {getObjectKeys(services).map((service) => {
            if (services[service]) {
              return (
                <Tooltip title={servicesNames[service]} key={service}>
                  <Grid item>
                    <ServiceIcon
                      serviceName={service}
                      isActive={
                        row.activeServiceTypes &&
                        row.activeServiceTypes.includes(
                          getKebabCaseServiceName(service),
                        )
                      }
                    />
                  </Grid>
                </Tooltip>
              );
            }
          })}
        </Box>
      ),
    },
    { label: 'Ärenden', renderer: (row: CustomerDTO) => row.tasks?.length },
    {
      label: '',
      renderer: () => (
        <IconButton size="large">
          <ArrowForwardIcon />
        </IconButton>
      ),
    },
  ];
  return (
    <Box sx={{ maxWidth: 'lg' }}>
      <CustomersToolbar
        onFiltersChange={(filterValues) => {
          setFilters(filterValues);
        }}
      />

      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <SimpleTable
            rows={users?.rows}
            columns={columns}
            onRowClick={(row) => navigate(`/customer/${row.id}`)}
          />

          {isFetching && <LinearProgress />}
          {isError && (
            <Typography variant="h1" align="center" padding={2}>
              Misslyckades hämta kunder.
            </Typography>
          )}
          {!isFetching && (users?.total === 0 || !users?.rows) && (
            <Typography variant="h1" align="center" padding={2}>
              Ingen data
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} component={Paper}>
          <Pagination
            count={users?.total}
            page={page}
            rowsPerPage={perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomersTable;
